// @ts-strict-ignore
import type { Event, Stacktrace } from "@sentry/types";

const isBeaconFunction = (functionName: string): boolean => {
  if (!functionName) return false;

  return functionName.includes("fireSendBeacon") || functionName.includes("sendBeacon");
};

const stackTraceFromBatBeacon = (stackTrace?: Stacktrace) => {
  if (!stackTrace) return false;

  return stackTrace.frames?.some(frame => isBeaconFunction(frame.function));
};

const errorFromBatBeacon = (event: Event): boolean => {
  return event?.exception?.values?.some(exception =>
    stackTraceFromBatBeacon(exception?.stacktrace)
  );
};

const batBeacon = (event: Event): Event | null => {
  if (errorFromBatBeacon(event)) {
    return null;
  }

  return event;
};

export { batBeacon };

const RupaLogo = props => (
  <svg
    width="62"
    height="64"
    viewBox="0 0 62 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M29.1888 63.9553C17.4934 63.4048 7.51433 56.0761 2.83267 45.6876C5.23152 47.5245 8.23909 48.4544 11.8022 48.4544C20.4128 48.4544 25.8568 40.9941 31.1214 33.7795C36.6487 26.205 41.8693 19.0503 50.4847 19.0503C55.3206 19.0503 59.0013 20.5444 60.7125 26.5939C61.0467 28.4663 61.2299 30.3931 61.2299 32.3654C61.2299 49.3401 48.2792 63.1904 32.024 63.9553H29.1888ZM11.8022 45.1256C8.32344 45.1256 5.55925 44.0593 3.58644 41.9566C0.525684 38.6939 0.0588074 33.7524 -0.0078125 31.9809C-0.00727524 31.9465 -0.00566347 31.9127 -0.00512621 31.8777C2.40823 26.8152 6.25715 22.3791 12.3394 22.3791C16.8626 22.3791 20.7276 27.6413 23.8325 31.8694C24.6604 32.9973 25.4453 34.0592 26.1948 34.9618C21.9934 40.5192 17.7045 45.1256 11.8022 45.1256ZM0.940984 24.4895C4.33054 10.835 16.3264 0.742188 30.6062 0.742188C42.1836 0.742188 52.2577 7.37806 57.4637 17.1657C55.5339 16.1954 53.2167 15.7216 50.4847 15.7216C40.2623 15.7216 34.3063 23.8831 28.5469 31.7762C28.4314 31.9349 28.3153 32.0935 28.1998 32.2516C27.6346 31.5354 27.0313 30.7165 26.4011 29.8583C22.8648 25.0432 18.4636 19.0503 12.3394 19.0503C7.94145 19.0503 4.07749 20.9294 0.940984 24.4895Z"
      fill="url(#paint0_linear_25913_153698)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_25913_153698"
        x1="52.0413"
        y1="9.19478"
        x2="10.9365"
        y2="57.4338"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.119792" stopColor="#1C749C" />
        <stop offset="0.645833" stopColor="#5DC1C7" />
      </linearGradient>
    </defs>
  </svg>
);

export default RupaLogo;

import EmersonLogoSVGComponent from "./EmersonLogo";
import SvgFullscriptLogoDarkGreenWhiteComponent from "./FullscriptLogoDarkGreenWhite";
import FullscriptLogoDarkTextGreenLeafSVGComponent from "./FullscriptLogoDarkTextGreenLeaf";
import FullscriptLogoLeafSVGComponent from "./FullscriptLogoLeaf";
import FullscriptLogoLeafDarkSVGComponent from "./FullscriptLogoLeafDark";
import FullscriptLogoWhiteTextGreenLeafSVGComponent from "./FullscriptLogoWhiteTextGreenLeaf";
import RupaLogoSvgComponent from "./RupaLogo";
import RupaMigrateArrowSVGComponent from "./RupaMigrateArrow";
import HipaaCompliantPrimarySVGComponent from "./footer/HipaaCompliantPrimary";
import HipaaCompliantWhiteSVGComponent from "./footer/HipaaCompliantWhite";
import SecureCheckoutPrimarySVGComponent from "./footer/SecureCheckoutPrimary";
import SecureCheckoutWhiteSVGComponent from "./footer/SecureCheckoutWhite";
import WellevateByFullscript from "./wellevate-by-fullscript.png";

const SvgEmersonLogo = EmersonLogoSVGComponent;
const SvgFullscriptLogoDarkGreenWhite = SvgFullscriptLogoDarkGreenWhiteComponent;
const SvgFullscriptIcon = FullscriptLogoLeafSVGComponent;
const SvgRupaIcon = RupaLogoSvgComponent;
const SvgRupaMigrateArrow = RupaMigrateArrowSVGComponent;
const SvgFullscriptIconDarkGreen = FullscriptLogoLeafDarkSVGComponent;
const SvgFullscriptLogo = FullscriptLogoWhiteTextGreenLeafSVGComponent;
const SvgFullscriptLogoDarkTextGreenLeaf = FullscriptLogoDarkTextGreenLeafSVGComponent;
const SvgHipaaLogoPrimary = HipaaCompliantPrimarySVGComponent;
const SvgHipaaLogoWhite = HipaaCompliantWhiteSVGComponent;
const SvgSecureCheckoutPrimary = SecureCheckoutPrimarySVGComponent;
const SvgSecureCheckoutWhite = SecureCheckoutWhiteSVGComponent;

export {
  SvgEmersonLogo,
  SvgFullscriptLogoDarkGreenWhite,
  SvgFullscriptIcon,
  SvgRupaIcon,
  SvgRupaMigrateArrow,
  SvgFullscriptIconDarkGreen,
  SvgFullscriptLogo,
  SvgFullscriptLogoDarkTextGreenLeaf,
  SvgHipaaLogoPrimary,
  SvgHipaaLogoWhite,
  SvgSecureCheckoutPrimary,
  SvgSecureCheckoutWhite,
  WellevateByFullscript,
};
